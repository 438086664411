.tng-LoadingIndicator {
  padding: 20px 0;
  text-align: center;
}

.tng-LoadingIndicator-img {
  animation: skewImg 2.5s infinite both;
  height: 80px;
}

.tng-LoadingIndicator-text {
  animation: pulse 1.25s infinite both;
  color: var(--color-blue-33);
}

@keyframes skewImg {
  0%  {
    transform: skew(40deg, 0);
  }

  50% {
    transform: skew(-20deg, 0);
  }

  100%  {
    transform: skew(40deg, 0);
  }
}

@keyframes pulse {
  0%  {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }

  100%  {
    opacity: 1;
  }
}
