.tng-ErrorIndicator {
  padding: 20px 0;
  text-align: center;
}

.tng-ErrorIndicator-img {
  animation: skullPulse 1s linear infinite alternate;
  height: 80px;
  margin: 0 0 10px 0;
}

.tng-ErrorIndicator-text {
  color: var(--color-red-52);
}

@keyframes skullPulse {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.15);
  }
}
