/* CUSTOM PROPERTIES */

:root {
  /* color naming convention: --color-[one word description]-[lightness value]-[alpha?] */
  --color-black-0-11: hsla(0, 0%, 0%, 0.11);
  --color-black-0-30: hsla(0, 0%, 0%, 0.30);
  --color-black-0-88: hsla(0, 0%, 0%, 0.88);

  --color-blue-33: hsl(193, 66%, 33%);
  --color-blue-33-85: hsla(193, 66%, 33%, 0.85);

  --color-gray-13: hsl(0, 0%, 13%);
  --color-gray-20: hsl(0, 0%, 20%);
  --color-gray-80: hsl(0, 0%, 80%);
  --color-gray-94-74: hsla(75, 13%, 94%, 0.74);
  --color-gray-94-95: hsla(75, 13%, 94%, 0.95);
  --color-gray-98: hsl(0, 0%, 98%);

  --color-green-18: hsl(138, 82%, 18%);
  --color-green-28: hsl(138, 82%, 28%);

  --color-orange-52: hsl(30, 76%, 52%);

  --color-red-27: hsl(0, 100%, 27%);
  --color-red-52: hsl(0, 72%, 52%);
}


/* FONTS */

/** Lora **/
@font-face {
  font-family: "Lora";
  src: url("myAssets/fonts/LoraRegular.woff2") format("woff2"),
    url("myAssets/fonts/LoraRegular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Lora";
  src: url("myAssets/fonts/LoraBold.woff2") format("woff2"),
    url("myAssets/fonts/LoraBold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

/** Louis George Cafe **/
@font-face {
  font-family: "Louis George Cafe Web";
  src: url("myAssets/fonts/LouisGeorgeCafe.woff2") format("woff2"),
    url("myAssets/fonts/LouisGeorgeCafe.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Louis George Cafe Web";
  src: url("myAssets/fonts/LouisGeorgeCafeItalic.woff2") format("woff2"),
    url("myAssets/fonts/LouisGeorgeCafeItalic.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Louis George Cafe Web";
  src: url("myAssets/fonts/LouisGeorgeCafeBold.woff2") format("woff2"),
    url("myAssets/fonts/LouisGeorgeCafeBold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Louis George Cafe Web";
  src: url("myAssets/fonts/LouisGeorgeCafeBoldItalic.woff2") format("woff2"),
    url("myAssets/fonts/LouisGeorgeCafeBoldItalic.woff") format("woff");
  font-style: italic;
  font-weight: 700;
}


/* ROOT STYLE */

* {
  box-sizing: border-box;
}

body {
  background-color: hsl(0, 0%, 98%);
  color: hsl(0, 0%, 13%);
  font-family: "Louis George Cafe Web", sans-serif;
  font-size: 16px;
  margin: 0;
  min-height: 100vh;
  padding: 0;
}

/** button: Normalize styling across browsers **/
button {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,
              transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover,
button:focus {
  background: none;
}

button:focus {
  outline: 0;
  outline-offset: 0;
}

button:active {
  transform: scale(0.99);
}


/* UTILITY CLASSES */
/* this is the only place where !important does NOT need to be avoided */

.u-desktopOnly {
  display: none;
}

@media (min-width: 781px) {
  .u-desktopOnly {
    display: block;
  }

  .u-mobileOnly {
    display: none !important;
  }
}
